:root {
  --client-paper-height: 96px;
  --oceanViewRadial: radial-gradient(circle, #69ff97 10%, #00e4ff 100%);

  --donkerblauw: #2a2135;
  --groen: #42c175;
  --lichtgrijs: #e7ecf0;
  --grijs: #8293a2;
  --donkergrijs: #657786;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Muli", "Roboto", sans-serif;
  font-size: 0.775rem;
  color: var(--donkerblauw);
}

/* *::-webkit-scrollbar {
  width: 2px!important;
  height: 2px!important;
}
*::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,.2);
} */
a.text-link,
a.text-link:visited {
  color: var(--groen);
  border-bottom: 1px solid white;
  text-decoration: none;
  transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
}

a.text-link:hover {
  border-bottom: 1px solid var(--groen);
}
.error-message {
  color: #ff0700;
  display: flex;
  font-size: 16px;
  align-items: center;
}
.error-message .icon {
  padding-right: 10px;
  width: 2rem;
  height: 2rem;
}
.modal-background {
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  align-items: center;
  justify-content: center;
}
.modal-background .modal {
  background: white;
  max-width: 400px;
  padding: 2rem;
  border-radius: 8px;
}
.modal-background .modal p {
  margin: 0;
}
.modal-background .modal h4 {
  margin: 0;
  text-align: center;
  font-size: 20px;
  margin-bottom: 1rem;
}
.modal-background .modal .modal-actions {
  margin: 1rem -1rem -1rem 0;
  display: flex;
  justify-content: flex-end;
}

.beta {
  background: var(--oceanViewRadial);
  font-size: 16px;
  -webkit-background-clip: text;
  color: transparent !important;
  font-style: italic;
  position: absolute;
  left: 80px;
  bottom: -10px;
}

img.emoji {
  height: 1em;
  width: 1em;
  margin: 0 0.05em 0 0.1em;
  vertical-align: -0.1em;
}

button.margin-top {
  margin-top: 16px;
}

div.full-width {
  width: 100% !important;
}

div.max-width {
  max-width: 700px;
}

div.paper {
  margin: 8px;
}

.header {
  width: 100%;
  text-align: center;
  padding: 1rem 0;
}

.content-container {
  padding: 0 24px 24px 24px;
}

div.summary h3 {
  padding: 24px;
}

h2.promo {
  text-align: center;
  font-weight: 300;
}

h2.promo strong {
  color: var(--groen);
  font-weight: bold;
  list-style-type: none;
}

ul.sales {
  list-style-type: none;
}

ul {
  padding: 0;
  margin: 16px 0;
  margin-left: 24px;
  position: relative;
}

ul li {
  margin: 16px 8px;
  padding-left: 32px;
  line-height: 24px;
}

ul li:last-of-type {
  margin-bottom: 0;
}

ul li.airplane::before {
  content: "";
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  left: 0;
  background: url("./assets/images/airplane.svg") no-repeat top left;
  transform: rotate(45deg);
  transition: transform 0.6s cubic-bezier(0.75, -0.5, 0, 1.75);
}

.suggestionView ul li {
  margin: 0;
  padding-left: 0;
}

ul li.airplane:hover::before {
  transform: rotate(320deg);
  transition: transform 0.6s cubic-bezier(0.75, -0.5, 0, 1.75);
}

ul li.chat::before {
  content: "";
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  left: 0;
  background: url("./assets/images/chat.svg") no-repeat top left;
  transform: rotateY(0);
  transition: all 0.6s cubic-bezier(0.75, -0.5, 0, 1.75);
}

ul li.chat:hover::before {
  transform: rotateY(540deg);
  background: url("./assets/images/forum.svg") no-repeat top left;
  transition: all 0.6s cubic-bezier(0.75, -0.5, 0, 1.75);
}

ul li.person::before {
  content: "";
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  left: 0;
  background: url("./assets/images/person.svg") no-repeat top left;
  transform: rotateY(0);
  transition: all 0.6s cubic-bezier(0.75, -0.5, 0, 1.75);
}

ul li.person:hover::before {
  transform: rotateY(540deg);
  background: url("./assets/images/people.svg") no-repeat top left;
  transition: all 0.6s cubic-bezier(0.75, -0.5, 0, 1.75);
}

/* Radio styles */
div.radio-item {
  border: 2px solid #d3d3d3;
  padding: 18px;
  margin: 8px 0;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

div.radio-item.selected {
  border: 4px solid var(--groen);
  padding: 16px;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

div.radio-item h3 {
  font-size: 20px;
  font-weight: bold;
}

/* Custom creditcard */

.CardField-number input {
  border: 1px solid var(--groen);
}

/* Vertical line  */
.vertical-line-container {
  position: relative;
  height: 100%;
}

.vertical-line {
  width: 1px;
  height: 100%;
  background: #000000;
  position: absolute;
  left: calc(50% - 1px);
  z-index: 1;
}

aside.block-text-white {
  display: block;
  background: #fff;
  position: relative;
  z-index: 2;
  padding: 4px 0;
  top: calc(50% - 18px);
}

/* Canvas styling */

canvas {
  position: absolute;
  z-index: 1;
}

div.front-of-canvas,
.front-of-canvas {
  position: relative;
  z-index: 12;
}

/* custom select styling */
.css-10nd86i .css-1rtrksz {
  padding: 0;
}

.css-10nd86i .css-fs1pt::before {
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  content: "";
  position: absolute;
  transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.42);
}

.css-10nd86i:hover .css-fs1pt::before {
  height: 2px;
  background-color: rgba(0, 0, 0, 0.87);
}

.css-10nd86i .css-1wx63ek::before {
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  content: "";
  position: absolute;
  transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  pointer-events: none;
  background-color: var(--groen);
}

/* pay page */
.payment-page * {
  margin: 0;
  padding: 0;
}

.logo {
  text-align: center;
  margin: 2rem;
}

.card-container {
  margin-top: 2rem;
}

.card-container + .card-container {
  margin-top: 0.5rem;
}

.button-container {
  text-align: center;
  padding: 1rem 0;
}

.order-container {
  background: var(--groen);
  color: white;
  border-radius: 3px 3px 0 0;
  padding: 2rem;
  display: block;
  text-align: center;
}

.order-container .price {
  font-weight: bold;
  font-size: 2rem;
  margin-top: 1rem;
}

.order-container .title {
  font-weight: normal;
  font-size: 1.4rem;
}

.server-message {
  background: white;
  padding: 2rem;
  text-align: center;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.server-message h3 {
  font-size: 1.2rem;
  font-weight: normal;
}

.server-message.success {
  background: var(--groen);
  border-radius: 3px;
}

.server-message.success h3 {
  color: white;
  font-weight: bold;
}

.server-message.failed h3 {
  color: #d8003a;
  font-weight: bold;
}

/* paper styling */
.paper {
  background: white;
  border-radius: 3px;
  /* box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07); */
  box-shadow: none !important;
}

/* stripe styling */
.card-container {
  display: flex;
  padding: 0 2rem;
  width: 100%;
  flex-wrap: wrap;
}

.card-container h4 {
  display: block;
  width: 100%;
  font-size: 16px;
  margin: 0 0.4rem;
}

.stripe-input {
  color: #000000;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-smoothing: antialiased;
  width: 100%;
  padding: 1rem 0 0.3rem;
  border-bottom: 1px solid var(--groen);
}

.stripe-input::placeholder {
  color: #aaa;
}

.stripe-input:-webkit-autofill {
  color: #aaa;
}
.main-app {
  display: block;
  background: var(--lichtgrijs);
}
.browser-warning {
  position: absolute;
  width: 100%;
  background: #ff5c00;
  z-index: 1300;
}
.browser-warning > div {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.browser-warning .icon {
  margin-right: 10px;
}
.browser-warning .close {
  position: absolute;
  right: 10px;
  cursor: pointer;
}
.chat-header .chat-title {
  text-align: center;
  width: calc(100% - 3rem);
}
.chat-header .chat-title h2 {
  margin: 0;
  /* margin-right: 3rem; */
}
.chat-header .drawer-toggle {
  margin: 1rem;
  width: 1rem;
  height: 1rem;
  color: var(--groen);
}

.dashboard {
  order: 1;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: calc(100% - 56px);
}
.dashboard > div {
  position: absolute;
  border: 0;
  width: 100%;
  display: flex;
  align-items: flex-end;
  background: none;
}
.dashboard > div > div {
  width: 100%;
}

.chat-wrapper {
  width: 100%;
  order: 2;
  position: relative;
  display: flex;
  align-items: flex-end;
}
.dashboard header {
  width: 100%;
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
}
.dashboard header .logo {
  width: 150px;
  margin: 0;
}
.waiting-for-customer {
  color: #ff5c00;
  background: #ff5c0033;
  display: flex;
  padding: 6px 16px;
  border-radius: 3px;
  font-weight: bold;
  align-items: center;
}
.waiting-for-customer .icon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
}
.client-info-wrapper {
  display: none;
}
.dashboard-aside {
  height: calc(100% - 80px);
  position: relative;
  top: calc(-100% + 80px);
  left: 100%;
  transition: left 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.dashboard-aside.open {
  left: 0;
  transition: left 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.dashboard-aside .paper {
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07) !important;
  box-sizing: border-box;
  margin: 0 20px 10px 20px;
  min-height: 30px;
  flex-grow: 2;
  position: relative;
  overflow: hidden;
}

.dashboard-aside .paper .paper-header {
  padding: 8px 20px;
  border-bottom: 2px solid #f4f9f8;
  display: flex;
  align-items: center;
  color: var(--groen);
}
.dashboard-aside .paper .paper-header h3 {
  color: var(--donkerblauw);
  margin: 5px 0;
}
.dashboard-aside .paper .paper-header .icon {
  width: 1rem;
  height: 1rem;
}
.dashboard .overview .paper:not(.active) {
  height: 30px;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.dashboard .equal-height .paper:not(.active) {
  height: calc(
    50% - 9px
  ); /* 50% of the total height - 1/2 of the client-info height*/
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.dashboard .overview .paper.client-info,
.dashboard .overview.equal-height .paper.client-info {
  height: 73px;
}

.dashboard .paper.active {
  height: calc(
    100% - ((40px * 2) + 10px)
  ); /* blocks-container height - (client-info + otherblocks small two times) + margin active block */
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.dashboard .paper .paper-header {
  cursor: pointer;
}

.dashboard .placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.3;
}
.dashboard .placeholder .icon {
  width: 3rem;
  height: 3rem;
}
.dashboard .placeholder h4 {
  font-size: 1.8rem;
  margin: 0;
}
.suggestion-addon {
  position: relative;
  width: calc(50% - 10px);
  margin: 0 5px 10px;
  padding: 0.5rem;
  border: 2px solid rgba(147, 112, 219, 1);
  border-radius: 5px;
  display: flex;
}
.suggestion-addon section {
  display: flex;
  align-items: center;
}
.suggestion-addon h3 {
  background: rgba(147, 112, 219, 0.4);
  width: 2.3rem;
  height: 2.3rem;
  color: rgba(147, 112, 219, 1);
  text-align: center;
  border-radius: 50%;
  margin: 0 1rem 0 0;
}
.suggestion-addon p {
  margin: 0;
  text-align: left;
}
.suggestion-addon .specs {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.proposal .paper-content ol {
  margin: 0;
  padding: 0;
}
.proposal .paper-content ol li {
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.proposal .paper-content ol li:not(.button) span {
  display: block;
  height: 35px;
  line-height: 35px;
}
.proposal .paper-content ol li:not(:last-of-type) {
  border-bottom: 1px solid #eaedeb;
}
.proposal .paper-content ol li.selected {
  color: #00d98e;
}
.proposal .paper-content ol li.selected .icon {
  width: 24px;
  height: 24px;
}

/* chat styling */
.dashboard-header {
  display: flex;
  align-items: center;
}
.dashboard-header .icon {
  width: 36px;
  height: 36px;
  padding: 5px;
  margin: 15px;
  position: relative;
  background: var(--donkerblauw);
  color: white;
  border-radius: 50%;
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  cursor: pointer;
}
.dashboard-header.closed .icon {
  background: var(--donkerblauw);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  padding: 5px;
  color: white;
  transition: all, 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.dashboard-header .client-menu {
  opacity: 1;
  position: absolute;
  background: white;
  text-align: left;
  padding: 1rem;
  z-index: 8;
  left: 1rem;
  top: 60px;
  width: 80%;
  border-radius: 3px;
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  box-shadow: 0 0 70px rgba(50, 50, 93, 0.15), 0 0 20px rgba(0, 0, 0, 0.17);
}
.dashboard-header .client-menu h3 {
  text-align: center;
  padding-bottom: 8px;
  border-bottom: 1px solid #eaedeb;
}
.dashboard-header .client-menu * {
  margin: 8px 0;
}
.dashboard-header .client-menu *:first-child {
  margin-top: 0;
}
.dashboard-header .client-menu *:last-child {
  margin-bottom: 0;
}
.dashboard-header .client-menu.closed {
  opacity: 0;
  z-index: -1;
  top: 40px;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.animatable ul {
  height: 100%;
}

.animatable {
  overflow-y: auto;
  background: inherit;
  will-change: transform;
  transition: transform 0.2s ease;
  height: calc(100% - 60px);
}

.animatable.prepare {
  /* We do this so we can tell when the "preparing" was done
     since there seems to be a browser bug where the preparation still takes
     time, even with `transition: none;` */
  transition-duration: 0.0000000000001s;
  opacity: 0;
}

.animatable.prepare.from.left {
  transform: translateX(-110%);
}
.animatable.prepare.from.right {
  transform: translateX(110%);
}
.animatable.prepare.to.left {
  transform: translateX(-1px);
}
.animatable.prepare.to.right {
  transform: translateX(1px);
}

.animatable:not(.prepare).to {
  pointer-events: none;
}
.animatable:not(.prepare).to.left {
  transform: translateX(-110%);
}
.animatable:not(.prepare).to.right {
  transform: translateX(110%);
}
.animatable:not(.prepare).from {
  transform: translateX(0);
}
div[role="tooltip"] > div {
  margin: 0;
}
